import { MDBCol, MDBRow, MDBCard, MDBCardTitle, MDBCardBody, MDBCardImage, MDBBtn } from 'mdb-react-ui-kit';
import { Profile } from './objects/objects';
import { googleLogout } from '@react-oauth/google';
import axios from 'axios';
import { buildDeleteUrl } from './objects/consts';


export default function buildProfile(profile: Profile, jwtToken: string, setProfile: React.Dispatch<React.SetStateAction<Profile | null>>) {

    const cleanUser = async (isCleanOnly: boolean, jwtToken: string) => {
        await axios.delete(buildDeleteUrl(isCleanOnly), {
            headers: {
                'Authorization': `Bearer ${jwtToken}`
            }
        });
        console.log('Clean finished');
    }

    const cleanProfile = async () => {
        console.log('Clean called');
        await cleanUser(true, jwtToken);
    }

    const deleteProfile = async () => {
        await cleanUser(true, jwtToken).then(logOut);
    }

    const logOut = () => {
        googleLogout();
        setProfile(null)
    };


    return (
        <MDBRow className="justify-content-center">
            <MDBCol md="8" lg="6" className="mt-5">
                <MDBCard style={{ borderRadius: '15px' }}>
                    <MDBRow className='ms-3 p-2'>
                        <MDBCol style={{ maxWidth: '20%' }}>
                            <MDBCardImage src={profile.iconURL} alt='Profile image' fluid />
                        </MDBCol>
                        <MDBCol style={{ maxWidth: '80%' }}>
                            <MDBCardTitle>{profile.nickName}</MDBCardTitle>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol>
                            <MDBCardBody>
                                <div className="flex-grow-1 ms-3">
                                    <div className="d-flex justify-content-start rounded-3 p-2 mb-2"
                                        style={{ backgroundColor: '#efefef' }}>
                                        <div>
                                            <p className="small text-muted mb-1">Send messages</p>
                                            <p className="mb-0">{profile.sendMessageCount}</p>
                                        </div>
                                        <div className="px-3">
                                            <p className="small text-muted mb-1">Likes</p>
                                            <p className="mb-0">{profile.likeCount}</p>
                                        </div>
                                        <div>
                                            <p className="small text-muted mb-1">Dislikes</p>
                                            <p className="mb-0">{profile.dislikeCount}</p>
                                        </div>
                                    </div>
                                    <MDBRow className="flex-column flex-md-row">
                                        <div className="d-grid gap-2 d-md-block">
                                            <MDBBtn outline className="me-1 flex-grow-1" onClick={cleanProfile}>Clean all data</MDBBtn>
                                            <MDBBtn outline className="me-1 flex-grow-1" onClick={deleteProfile}>Delete profile</MDBBtn>
                                            <MDBBtn className="flex-grow-1" onClick={logOut}>Log out</MDBBtn>
                                        </div>
                                    </MDBRow>
                                </div>
                            </MDBCardBody>
                        </MDBCol>
                    </MDBRow>
                </MDBCard>
            </MDBCol>
        </MDBRow>
    );
}