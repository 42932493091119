import { useEffect, useState } from 'react';
import './App.css';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { Profile, Credential } from './objects/objects';
import buildProfile from './Profile';
import { MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCol, MDBRow } from 'mdb-react-ui-kit';
import { HOST } from './objects/consts';


function App() {
  const [credential, setUser] = useState<Credential | null>(null);
  const [profile, setProfile] = useState<Profile | null>(null);

  useEffect(() => {
    const fetchUser = async (jwtToken: string) => axios.get(`${HOST}/user/profile`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    });

    if (credential?.credential) {
      fetchUser(credential.credential).then(result => {
        if (result.status == 200) {
          setProfile(result.data);
        }
      });
    }
  }, [credential]);


  return (
    <div>
      <header className="App-header">
        <title>Bizzer app</title>
        <img src="https://cdn.bizzer.xyz/staticfiles/ic_bizzer-playstore.png" alt="logo" className='App-logo' />
        <h2>
          Bizzer app
        </h2>
      </header>
      <div>
        {profile ? (
          buildProfile(profile, credential!.credential!, setProfile)
        ) : (
          <MDBRow className="justify-content-center">
            <MDBCol md={2} className="mt-5">
              <MDBCard>
                <MDBCardImage src='https://cdn.bizzer.xyz/staticfiles/ic_bizzer-playstore.png' position='top' alt='Logo' />
                <MDBCardBody alignment='center'>
                  <MDBCardTitle>Please login</MDBCardTitle>
                  <GoogleLogin
                    size='large'
                    onSuccess={credentialResponse => {
                      setUser(credentialResponse);
                    }}
                    onError={() => {
                      console.log('Login Failed');
                    }} />
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        )}
      </div>
    </div>
  );
}

export default App;
